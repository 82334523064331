// Required imports from chartiq for advanced chart

// @ts-ignore
import { CIQ } from 'chartiq/js/chartiq'
// @ts-ignore
import 'chartiq/js/advanced'

import 'chartiq/js/addOns'

// Symbol mapping to market definition
import 'chartiq/examples/markets/marketDefinitionsSample'
import 'chartiq/examples/markets/marketSymbologySample'

import 'chartiq/examples/feeds/symbolLookupChartIQ'

import 'chartiq/examples/translations/translationSample'

import 'chartiq/js/componentUI'
import 'chartiq/js/components'

// Event Markers
// @ts-ignore
// import marker from 'chartiq/examples/markers/markersSample'
// import 'chartiq/examples/markers/tradeAnalyticsSample'
// import 'chartiq/examples/markers/videoSample'

import 'chartiq/js/extras/svgcharts/piechart.js'

// import 'chartiq/examples/help/helpContent.js'

// @ts-ignore
import quoteFeed from 'chartiq/examples/feeds/quoteFeedSimulator.js'

// Uncomment the following for the forecasting simulator (required for the forecasting sample).
// @ts-ignore
// import forecastQuoteFeed from 'chartiq/examples/feeds/quoteFeedForecastSimulator.js'

// @ts-ignore
import PerfectScrollbar from 'chartiq/js/thirdparty/perfect-scrollbar.esm.js'

// @ts-ignore
import defaultConfig from 'chartiq/js/defaultConfiguration'

// Plugins

// Crypto, L2 Heat Map, Market Depth,
// Important Note. Uncomment the corresponding configuration object below when enabling this plugin.
// import 'chartiq/plugins/activetrader/cryptoiq'

// ScriptIQ
// import 'chartiq/plugins/scriptiq/scriptiq'

// Trading Central: Technical Insights
// import 'chartiq/plugins/technicalinsights/components'

// TFC plugin
// Important Note. Uncomment the corresponding configuration object below when enabling this plugin.
// import 'chartiq/plugins/tfc/tfc-loader'
// import 'chartiq/plugins/tfc/tfc-demo' /* if using demo account class */

// Time Span Events
// Important Note. Uncomment the corresponding configuration object below when enabling this plugin.
// import 'chartiq/plugins/timespanevent/timespanevent'
// import 'chartiq/plugins/timespanevent/examples/timeSpanEventSample' /* if using sample */

// Trading Central: Analyst Views
// import 'chartiq/plugins/analystviews/components'

// Visual Earnings
// Important Note. Uncomment the corresponding configuration object below when enabling this plugin.
// import 'chartiq/plugins/visualearnings/visualearnings'

// Uncomment the following for the L2 simulator (required for the crypto sample and MarketDepth addOn)
// import 'chartiq/examples/feeds/L2_simulator' /* for use with cryptoiq */
import sample5min from "chartiq/examples/data/STX_SAMPLE_5MIN.js";
// Creates a complete customised configuration object
function getConfig() {
	return defaultConfig({
	
		// forecastQuoteFeed, // uncomment to enable forecast quote feed simulator
		scrollStyle: PerfectScrollbar
	})
}

// Creates a complete customised configuration object
function getCustomConfig({
	chartId,
	symbol,
	restore,
	onChartReady
}: {
	chartId?: string
	symbol?: string | { symbol: string; name?: string; exchDisp?: string }
	restore?: boolean
	onChartReady?: Function
} = {}) {
	const config = getConfig()

	// Update chart configuration by modifying default configuration
	config.chartId = chartId || '_advanced-chart'
	config.initialSymbol = symbol || {
		symbol: 'Tasq',
		name: 'Tasq Inc',
	
	}
	console.log(sample5min)
	config.initialData = [
		{
			"Date": "2022-01-17T08:06:15",
			"Open": 217.1912,
			"Arrival": "NaN",
			"Close": 233.6637,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-17T13:28:39",
			"Open": 212.1452,
			"Arrival": "NaN",
			"Close": 214.677,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-17T14:21:15",
			"Open": 213.1624,
			"Arrival": "NaN",
			"Close": 231.428,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-17T15:21:16",
			"Open": 211.4272,
			"Arrival": "NaN",
			"Close": 229.0314,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-18T01:06:17",
			"Open": 207.5976,
			"Arrival": "NaN",
			"Close": 243.4522,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-18T02:06:16",
			"Open": 197.5379,
			"Arrival": "NaN",
			"Close": 230.0747,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-18T03:06:16",
			"Open": 211.9276,
			"Arrival": "NaN",
			"Close": 240.0953,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-18T12:51:16",
			"Open": 203.5602,
			"Arrival": "NaN",
			"Close": 233.6145,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-18T13:51:15",
			"Open": 210.9946,
			"Arrival": "NaN",
			"Close": 232.5226,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-18T16:51:15",
			"Open": 137.3402,
			"Arrival": "NaN",
			"Close": 236.9979,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-19T06:58:40",
			"Open": 215.9122,
			"Arrival": "NaN",
			"Close": 224.2467,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-19T07:58:40",
			"Open": 206.1454,
			"Arrival": "NaN",
			"Close": 222.745,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-19T08:58:40",
			"Open": 213.9002,
			"Arrival": "NaN",
			"Close": 224.1803,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-19T09:51:15",
			"Open": 205.8639,
			"Arrival": "NaN",
			"Close": 217.5752,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-19T19:36:16",
			"Open": 209.9192,
			"Arrival": "NaN",
			"Close": 238.4589,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-19T20:36:15",
			"Open": 208.5435,
			"Arrival": "NaN",
			"Close": 234.3736,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-19T21:36:16",
			"Open": 214.0955,
			"Arrival": "NaN",
			"Close": 227.9256,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-19T22:36:15",
			"Open": 167.6042,
			"Arrival": "NaN",
			"Close": 230.9477,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-20T09:06:16",
			"Open": 216.8797,
			"Arrival": "NaN",
			"Close": 232.3745,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-20T10:06:16",
			"Open": 218.9144,
			"Arrival": "NaN",
			"Close": 230.0725,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-20T20:51:14",
			"Open": 227.2469,
			"Arrival": "NaN",
			"Close": 237.2884,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-20T21:58:40",
			"Open": 228.8524,
			"Arrival": "NaN",
			"Close": 235.294,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-20T22:51:16",
			"Open": 223.3873,
			"Arrival": "NaN",
			"Close": 236.7088,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-20T23:51:16",
			"Open": 195.1184,
			"Arrival": "NaN",
			"Close": 230.2534,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-21T00:51:16",
			"Open": 216.2142,
			"Arrival": "NaN",
			"Close": 229.2765,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-22T00:06:15",
			"Open": 216.2607,
			"Arrival": "NaN",
			"Close": 236.31,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-22T01:06:16",
			"Open": 220.1949,
			"Arrival": "NaN",
			"Close": 233.3091,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-22T02:06:17",
			"Open": 220.9876,
			"Arrival": "NaN",
			"Close": 238.212,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-22T12:58:39",
			"Open": 220.7804,
			"Arrival": "NaN",
			"Close": 242.5041,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-22T13:58:40",
			"Open": 186.3606,
			"Arrival": "NaN",
			"Close": 237.3644,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-22T14:58:39",
			"Open": 217.2099,
			"Arrival": "NaN",
			"Close": 239.2753,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-23T05:06:16",
			"Open": 215.3972,
			"Arrival": "NaN",
			"Close": 237.4034,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-23T06:06:15",
			"Open": 194.779,
			"Arrival": "NaN",
			"Close": 240.826,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-23T07:06:14",
			"Open": 216.9351,
			"Arrival": "NaN",
			"Close": 233.1861,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-23T07:58:40",
			"Open": 219.588,
			"Arrival": "NaN",
			"Close": 232.0994,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-23T20:36:18",
			"Open": 217.6538,
			"Arrival": "NaN",
			"Close": 237.8994,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-23T21:36:15",
			"Open": 217.474,
			"Arrival": "NaN",
			"Close": 241.8424,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-24T07:28:40",
			"Open": 219.7679,
			"Arrival": "NaN",
			"Close": 236.28,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-24T08:28:39",
			"Open": 218.0509,
			"Arrival": "NaN",
			"Close": 234.4872,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-24T18:06:15",
			"Open": 224.1679,
			"Arrival": "NaN",
			"Close": 227.6131,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-25T05:58:40",
			"Open": 164.9449,
			"Arrival": "NaN",
			"Close": 225.1697,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-25T06:58:40",
			"Open": 219.2667,
			"Arrival": "NaN",
			"Close": 243.3142,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-25T07:58:40",
			"Open": 218.1913,
			"Arrival": "NaN",
			"Close": 231.4489,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-25T14:06:15",
			"Open": 226.2814,
			"Arrival": "NaN",
			"Close": 248.1919,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-25T15:06:16",
			"Open": 180.3927,
			"Arrival": "NaN",
			"Close": 246.387,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-25T16:06:15",
			"Open": 227.9252,
			"Arrival": "NaN",
			"Close": 241.3162,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-25T17:06:15",
			"Open": 228.4048,
			"Arrival": "NaN",
			"Close": 248.8396,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-26T02:58:40",
			"Open": 215.8278,
			"Arrival": "NaN",
			"Close": 236.0875,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-26T03:58:40",
			"Open": 225.3588,
			"Arrival": "NaN",
			"Close": 240.4039,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-26T04:51:15",
			"Open": 161.4922,
			"Arrival": "NaN",
			"Close": 241.9167,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-26T15:36:16",
			"Open": 212.6745,
			"Arrival": "NaN",
			"Close": 237.7683,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-26T16:36:15",
			"Open": 220.7504,
			"Arrival": "NaN",
			"Close": 239.2305,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-27T04:06:17",
			"Open": 174.8886,
			"Arrival": "NaN",
			"Close": 242.1972,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		},
		{
			"Date": "2022-01-27T05:21:16",
			"Open": 215.6037,
			"Arrival": "NaN",
			"Close": 234.8448,
			"Low": "NaN",
			"High": "NaN",
			"Volume": "NaN"
		}
	]
	if (typeof restore == 'boolean') config.restore = restore

	// config.quoteFeeds[0].behavior.refreshInterval = 0; // disables quotefeed refresh
	config.onChartReady = onChartReady

	// Select only plugin configurations that needs to be active for this chart
	const {
		/* eslint-disable */
		marketDepth,
		termStructure,
		tfc,
		timeSpanEventPanel,
		visualEarnings
		/* eslint-enable */
	} = config.plugins
	config.plugins = {
		// marketDepth,
		// termStructure,
		// tfc,
		// timeSpanEventPanel,
		// visualEarnings
	}

	// Enable / disable addOns
	// config.enabledAddOns.tooltip = false;
	// config.enabledAddOns.continuousZoom = true;

	return config
}

export { CIQ, getConfig, getCustomConfig }